<template>
  <div class="libary">
    <a-tabs v-model="activeKey" type="card" @change="handleTabChange">
      <a-tab-pane key="SIKB-heat-analysis">
        <span slot="tab"> <a-icon type="edit" />Heat Analysis </span>
      </a-tab-pane>
      <a-tab-pane key="SIKB-connectivity-search">
        <span slot="tab"> <a-icon type="snippets" />Connectivity Search </span>
      </a-tab-pane>
    </a-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import { users } from "@/api/modules/login"

export default {
  name: "KnowlageLibary",
  computed: {
    ...mapState("analysis", ["loading"]),
  },
  data() {
    return {
      activeKey: "SIKB-heat-analysis",
    }
  },
  beforeRouteEnter(to, from, next) {
    users()
      .then((res) => {
        this.$store.commit("analysis/setAuthenticated", true)
      })
      .catch((error) => {
        if (error.code === 401) {
          this.$store.commit("analysis/setAuthenticated", false)
          this.$router.push({ name: "Login" })
        }
      })
      .finally(() => {
        next((vm) => {
          vm.$store.commit("analysis/setLoading", false)
        })
      })
  },
  mounted() {
    this.activeKey = this.$route.name
  },
  methods: {
    handleTabChange(value) {
      // this.$store.commit("analysis/setLoading", true)
      this.activeKey = value
      this.$router.push({
        name: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.libary {
  margin-top: 1.5em;
  padding: 0 32px;
}
</style>
